exports.components = {
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kili-privacy-policy-js": () => import("./../../../src/pages/kili-privacy-policy.js" /* webpackChunkName: "component---src-pages-kili-privacy-policy-js" */),
  "component---src-pages-kili-signup-js": () => import("./../../../src/pages/kili-signup.js" /* webpackChunkName: "component---src-pages-kili-signup-js" */),
  "component---src-pages-my-files-js": () => import("./../../../src/pages/my-files.js" /* webpackChunkName: "component---src-pages-my-files-js" */),
  "component---src-pages-wikipedia-game-js": () => import("./../../../src/pages/wikipedia-game.js" /* webpackChunkName: "component---src-pages-wikipedia-game-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

